@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.Learn {
  align-items: flex-start;
  background-color: $dark_blue;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px 30px;

  @include breakpoint(desktop-medium) {
    background-size: -60% 100%;
    padding: 150px 192px 0;
  }

  .title {
    display: flex;

    h4 {
      align-items: center;
      color: $white;
      display: flex;
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
      margin: 0 0 30px 0;
      text-align: center;

      span {
        align-items: center;
        background-color: $aqua_blue;
        border-radius: 50%;
        color: $dark_blue;
        display: flex;
        font-family: $primary_font_demi;
        font-size: pxToRem(30px);
        height: 41px;
        justify-content: center;
        margin-right: 20px;
        width: 41px;

        @include breakpoint(desktop-small) {
          align-items: center;
          flex-direction: column;
          font-size: pxToRem(40px);
          height: 71px;
          justify-content: center;
          margin-right: 40px;
          width: 71px;
        }
      }

      @include breakpoint(desktop-small) {
        flex-direction: row;
        font-size: pxToRem(34px);
        // margin-bottom: 116px;
        width: auto;
      }
    }
  }

  .container-learn {
    align-items: center;
    background: #0b0d54 0% 0% no-repeat padding-box;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 100px 0;
    justify-content: center;
    padding: 93px 70px;
    width: 100%;

    @include breakpoint(desktop-small) {
      border-radius: 29px;
      flex-direction: row;
      justify-content: space-evenly;
    }

    @include breakpoint(desktop-large) {
      gap: 0 80px;
      justify-content: space-evenly;
    }

    .stage-learn {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 226px;

      @include breakpoint(desktop-small) {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      strong {
        color: $aqua_blue;
        font-family: $primary_font_demi;
        font-size: 21px;
        margin: 75px 0 30px 0;
        text-align: left;
        width: 100%;
      }

      p {
        color: white;
        font-family: $primary_font;
        font-size: 17px;
        height: 146px;
        margin: 3px 0;
        text-align: left;
        width: 100%;
      }

      img {
        height: 275px;
        width: 136px;
      }

      &.empty-height {
        display: flex;
        height: 100%;
        justify-content: center;
        width: 212px;

        div {
          display: flex;
          flex-direction: column;
          height: 240px;
          width: 100%;

          strong {
            color: $aqua_blue;
            font-family: $primary_font_demi;
            font-size: 21px;
            margin: 80px 0 30px 0;
          }

          p {
            color: white;
            font-family: $primary_font;
            font-size: 17px;
            margin: 3px 0;
          }
        }
      }
    }
  }

  .container-info {
    border-radius: 29px;
    border: 3px solid #070bb9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 80px 0 0 0;
    min-height: 191px;
    padding: 20px 20px;
    width: 100%;

    p {
      color: $white;
      font-family: $primary_font;
      font-size: 17px;
      width: 87%;

      span {
        color: $aqua_blue;
        font-family: $primary_font_demi;
      }
    }

    @include breakpoint(desktop-small) {
      padding: 20px 90px;
    }
  }

  .container-visibles {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .title-module {
      align-items: center;
      background: #000255 0% 0% no-repeat padding-box;
      border-radius: 22px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      height: 133px;
      margin-top: 72px;
      padding: 20px 32px;
      position: relative;
      width: 100%;

      @include breakpoint(desktop-small) {
        padding: 40px 62px;
      }

      &.reverse {
        p {
          color: $aqua_blue;

          span {
            color: $white;
          }
        }
      }

      img {
        height: 52px;
        width: 26px;
      }

      p {
        color: $white;
        font-family: $primary_font_demi;
        font-size: 15px;
        margin-left: 23px;
        width: 60%;

        span {
          color: $aqua_blue;
        }

        @include breakpoint(desktop-small) {
          font-size: 21px;
          margin-left: 60px;
          width: 100%;
        }
      }

      .circle-click {
        align-items: center;
        border-radius: 50%;
        border: 2px solid #ffffff;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        right: 10%;
        width: 40px;

        @include breakpoint(desktop-small) {
          right: 60px;
        }

        span {
          color: $white;
          font-family: $primary_font;
          font-size: 41px;
          height: 54px;
          margin: 0;
        }
      }
    }

    .container-module {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;
      position: relative;
      transition: max-height 0.5s ease-in-out;
      // width: 88%;
      width: 100%;

      @include breakpoint(desktop-medium) {
        padding-left: 150px;
      }

      &.one {
        gap: 50px;

        @include breakpoint(desktop-small) {
          gap: 111px;
        }
      }

      &.width {
        width: 100%;
      }

      &.open {
        max-height: 200vh;
      }

      .text-containers {
        max-width: 429px;
        padding-top: 60px;

        @include breakpoint(desktop-large) {
          max-width: 560px;
          padding-top: 110px;
        }

        p {
          color: white;
          font-family: $primary_font;
          font-size: 16px;
          line-height: 40px;
          margin: 0;

          span {
            color: #01c7f0;
            font-family: $primary_font_demi;
          }
        }

        .title-desktop {
          display: none;

          @include breakpoint(desktop-medium) {
            display: flex;
          }
        }

        .title-mobile {
          display: flex;

          @include breakpoint(desktop-medium) {
            display: none;
          }
        }
      }

      .infos-div {
        align-items: center;
        border-radius: 9px;
        border: 1px solid $blue;
        display: flex;
        margin-bottom: 13px;
        padding: 20px 40px 20px 0;
        position: relative;

        p {
          color: $white;
          font-family: $primary_font;
          font-size: 18px;
          margin: 2px;

          .info-background {
            background-color: #0b0d54;
            border-radius: 9px;
            margin-top: 20px;
            padding: 10px 15px;

            span {
              color: $aqua_blue;
              font-family: $primary_font_demi;
            }
          }
        }

        .ball {
          background-color: $blue;
          border-radius: 50%;
          border: 1px solid $blue;
          display: inline-block;
          margin: 0 20px;
          min-height: 8px;
          min-width: 8px;
        }

        .ball-height {
          margin: 0 20px;
          min-height: 8px;
          min-width: 8px;

          .ball {
            left: 0;
            position: absolute;
            top: 28px;
          }
        }
      }

      .title {
        display: flex;
        justify-content: flex-start;
        text-align: center;
        width: 100%;
        margin: 0 0 40px 0;

        @include breakpoint(desktop-small) {
          margin: 0 0 50px 50px;
        }

        p {
          color: $white;
          font-family: $primary_font_demi;
          font-size: 18px;
          margin: 0;

          span {
            color: $aqua_blue;
            font-family: $primary_font_demi;
          }
        }
      }

      .arrow-container {
        display: none;
        margin: 0 30px;
        width: 30px;

        .arrow {
          bottom: 5px;
          color: $white;
          font-size: 70px;
          position: absolute;
        }
      }

      .image-module {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;

        img {
          max-height: 580px;
          max-width: 286px;
        }
      }

      .container-infos-texts {
        // margin-top: 49px;
      }

      @include breakpoint(desktop-small) {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;

        .arrow-container {
          display: flex;
        }

        .image-module {
          width: 35%;
        }

        .title {
          max-width: 90%;
        }

        .container-infos-texts {
          width: 55%;
          padding-top: 60px;

          @include breakpoint(desktop-large) {
            padding-top: 110px;
          }
        }
      }

      .container-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;

        .card {
          border-radius: 9px;
          border: 1px solid #070bb9;
          min-height: 300px;
          padding: 15px;
          width: min-content;
          width: 190px;

          @include breakpoint(desktop-large) {
            min-height: 300px;
            min-width: 219px;
            padding: 15px;
            width: 219px;
          }

          .text {
            color: $white;
            font-family: $primary_font;
            font-size: 15px;
            line-height: 25px;
            margin-top: 20px;
            padding: 0 10px;

            @include breakpoint(desktop-medium) {
              font-size: 17px;
            }
          }

          .card-title {
            height: 101px;

            &.blue {
              border: 1px solid $aqua_blue;
            }

            p {
              border: 1px solid #db1ab8;
              border-radius: 9px;
              padding: 5px 10px !important;
              color: $white;
              font-family: $primary_font_demi;
              font-size: 15px;
              margin: 0;
            }

            @include breakpoint(desktop-medium) {
              p {
                font-size: 21px;
              }
            }
          }
        }

        .arrow-pink {
          display: none;

          @include breakpoint(desktop-large) {
            display: flex;
          }
        }
      }

      .text-cards,
      .text-width {
        color: white;
        font-family: $primary_font_demi;
        font-size: 17px;
        margin-top: 78px;

        &.fine {
          font-family: $primary_font;
        }

        &.blue {
          span {
            color: $aqua_blue;
          }
        }

        span {
          color: #db1ab8;
        }
      }
    }

    .container-bonus {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: #000248;
      // width: 88%;
      width: 100%;
      border-radius: 22px;
      margin-top: 100px;
      padding: 20px 20px;

      @include breakpoint(desktop-small) {
        padding: 60px 133px;
      }

      .container-section {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;

        @include breakpoint(desktop-small) {
          flex-direction: row;
        }

        @include breakpoint(desktop-medium) {
          gap: 50px;
        }
      }

      h6 {
        color: white;
        font-family: 'Museo Sans Bold';
        font-size: 23px;
        margin: 0;

        strong {
          color: #db1ab8;
        }

        @include breakpoint(desktop-medium) {
          font-size: 30px;
        }
      }

      @include breakpoint(desktop-medium) {
        // padding-left: 150px;
        padding: 60px 133px;
        gap: 50px;
      }

      &.one {
        gap: 50px;

        @include breakpoint(desktop-small) {
          gap: 111px;
        }
      }

      &.width {
        width: 100%;
      }

      &.open {
        max-height: 200vh;
      }

      .text-containers {
        max-width: 429px;
        padding-top: 60px;

        @include breakpoint(desktop-large) {
          max-width: 560px;
        }

        p {
          color: white;
          font-family: $primary_font;
          font-size: 16px;
          line-height: 40px;
          margin: 0;

          span {
            color: #01c7f0;
            font-family: $primary_font_demi;
          }
        }

        .title-desktop {
          display: none;

          @include breakpoint(desktop-medium) {
            display: flex;
          }
        }

        .title-mobile {
          display: flex;

          @include breakpoint(desktop-medium) {
            display: none;
          }
        }
      }

      .infos-div {
        align-items: center;
        border-radius: 9px;
        border: 1px solid $blue;
        display: flex;
        margin-bottom: 13px;
        padding: 20px 40px 20px 0;
        position: relative;

        @include breakpoint(desktop-small) {
          min-width: 358px;
        }

        @include breakpoint(desktop-medium) {
          max-width: 500px;
        }

        p {
          color: $white;
          font-family: $primary_font;
          font-size: 18px;
          margin: 2px;

          .info-background {
            background-color: #0b0d54;
            border-radius: 9px;
            margin-top: 20px;
            padding: 10px 15px;

            span {
              color: $aqua_blue;
              font-family: $primary_font_demi;
            }
          }
        }

        .ball {
          background-color: $blue;
          border-radius: 50%;
          border: 1px solid $blue;
          display: inline-block;
          margin: 0 20px;
          min-height: 8px;
          min-width: 8px;
        }

        .ball-height {
          margin: 0 20px;
          min-height: 8px;
          min-width: 8px;

          .ball {
            left: 0;
            position: absolute;
            top: 28px;
          }
        }
      }

      .title {
        display: flex;
        justify-content: flex-start;
        text-align: center;
        width: 100%;
        margin: 0 0 40px 0;

        @include breakpoint(desktop-small) {
          // margin: 0 0 50px 50px;
        }

        p {
          color: $white;
          font-family: $primary_font_demi;
          font-size: 18px;
          margin: 0;

          span {
            color: $aqua_blue;
            font-family: $primary_font_demi;
          }
        }
      }

      .arrow-container {
        display: none;
        margin: 0 30px;
        width: 30px;

        .arrow {
          bottom: 5px;
          color: $white;
          font-size: 70px;
          position: absolute;
        }
      }

      .image-module {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;

        img {
          max-height: 580px;
          max-width: 286px;
        }
      }

      .container-infos-texts {
        // margin-top: 49px;
      }

      @include breakpoint(desktop-small) {
        align-items: flex-start;
        // flex-direction: row;
        justify-content: space-between;

        .arrow-container {
          display: flex;
        }

        .image-module {
          width: 35%;
        }

        .title {
          max-width: 90%;
        }

        .container-infos-texts {
          // width: 55%;
          padding-top: 60px;
        }
      }

      .container-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;

        .card {
          border-radius: 9px;
          border: 1px solid #070bb9;
          min-height: 300px;
          padding: 15px;
          width: min-content;
          width: 190px;

          @include breakpoint(desktop-large) {
            min-height: 300px;
            min-width: 219px;
            padding: 15px;
            width: 219px;
          }

          .text {
            color: $white;
            font-family: $primary_font;
            font-size: 15px;
            line-height: 25px;
            margin-top: 20px;
            padding: 0 10px;

            @include breakpoint(desktop-medium) {
              font-size: 17px;
            }
          }

          .card-title {
            height: 101px;

            &.blue {
              border: 1px solid $aqua_blue;
            }

            p {
              border: 1px solid #db1ab8;
              border-radius: 9px;
              padding: 5px 10px !important;
              color: $white;
              font-family: $primary_font_demi;
              font-size: 15px;
              margin: 0;
            }

            @include breakpoint(desktop-medium) {
              p {
                font-size: 21px;
              }
            }
          }
        }

        .arrow-pink {
          display: none;

          @include breakpoint(desktop-large) {
            display: flex;
          }
        }
      }

      .text-cards,
      .text-width {
        color: white;
        font-family: $primary_font_demi;
        font-size: 17px;
        margin-top: 78px;

        &.fine {
          font-family: $primary_font;
        }

        &.blue {
          span {
            color: $aqua_blue;
          }
        }

        span {
          color: #db1ab8;
        }
      }
    }
  }
}
