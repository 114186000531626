@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.ButtonContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .Button {
    align-items: center;
    background-color: $aqua_blue;
    border-radius: 54px;
    border: 2px solid $aqua_blue;
    color: black;
    cursor: pointer;
    display: inline-flex;
    font-family: $primary_font_demi;
    font-size: pxToRem(15px);
    height: 47px;
    padding: 12px 22px;
    transition: filter 0.2s;

    @include breakpoint(tablet) {
      font-size: pxToRem(19px);
    }

    &:hover {
      filter: brightness(0.9);
    }

    &.active {
      background-color: $aqua_blue;
      color: $white;
    }
  }
}
