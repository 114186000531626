@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.ContentAvaliation {
  .header-content {
    display: flex;
    flex-direction: column;
    gap: 0 118px;

    @include breakpoint(tablet-large) {
      align-items: flex-end;
      flex-direction: row;
    }

    .two-button {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include breakpoint(desktop-small) {
        flex-direction: row;
      }
    }

    .card-status {
      background: #fafbfb 0% 0% no-repeat padding-box;
      border-radius: 17px;
      box-shadow: 0px 1px 17px #0000000d;
      display: flex;
      flex-direction: column;
      height: 110px;
      justify-content: space-between;
      padding: 24px 30px 20px;
      width: 100%;

      div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        span {
          font-family: $primary_font_heavy;
          font-size: 30px;
        }
      }

      p {
        margin: 0;
        color: #010230;
        font-family: $primary_font_light;
        font-size: 17px;
      }

      strong {
        margin: 0;
        font-family: $primary_font_heavy;
        font-size: 18px;
      }

      @include breakpoint(tablet-large) {
        height: 195px;
        max-width: 531px;
        min-width: 352px;
        padding: 34px 30px 44px;

        p {
          font-size: 18px;
        }

        strong {
          font-size: 18px;
        }

        div {
          span {
            font-size: 30px;
          }
        }
      }

      @include breakpoint(desktop-medium) {
        p {
          font-size: 24px;
        }

        strong {
          font-size: 24px;
        }

        div {
          span {
            font-size: 44px;
          }
        }
      }
    }

    .description-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;

      strong {
        font-family: $primary_font_heavy;
        font-size: 24px;
      }

      p {
        color: #808080;
        font-family: $primary_font_light;
        font-size: 14px;
      }

      button {
        align-items: center;
        background: #ffffff;
        border-radius: 15px;
        border: 1px solid #010230;
        box-sizing: border-box;
        color: #010230;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_demi;
        font-size: 16px;
        gap: 0 21px;
        height: 51px;
        justify-content: space-between;
        max-width: 255px;
        padding: 17px 18px;
        width: 100%;

        &:hover {
          background-color: #010230;
          border: 1px solid white;
          color: white;
        }

        img {
          height: 25px;
          width: 24px;
        }
      }

      @include breakpoint(tablet-large) {
        max-width: 514px;
        padding: 0;

        strong {
          font-size: 18px;
        }

        p {
          font-size: 13px;
        }
      }

      @include breakpoint(desktop-medium) {
        strong {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .line-separator {
    align-items: center;
    background-color: #f2f4f5;
    border-radius: 9px;
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin: 60px 0 42px 0;
    padding: 20px 30px;
    width: 100%;

    p {
      color: #010230;
      font-family: $primary_font_light;
      font-size: 18px;
      margin: 0;

      @include breakpoint(tablet-large) {
        font-size: 21px;
      }

      strong {
        font-family: $primary_font_heavy;
      }
    }
  }

  .body-content {
    background: #fafbfb 0% 0% no-repeat padding-box;
    border-radius: 17px;
    box-shadow: 0px 1px 17px #0000000d;
    box-sizing: border-box;
    min-height: 300px;
    padding: 20px;

    @include breakpoint(tablet-large) {
      padding: 55px;
    }
    
    span {
      color: #010230;
      font-family: $primary_font_light;
      font-size: 21px;
      font-style: italic;
      text-transform: uppercase;
    }

    .approved {
      display: flex;
      flex-direction: column;
      gap: 30px 0;

      span {
        font-family: $primary_font_demi;
        font-size: 24px;
        font-style: unset;
        text-transform: unset;
      }

      p {
        color: #010230;
        font-size: 17px;
        font-style: unset;
        margin: 0;
      }

      button {
        align-items: center;
        background-color: #010230;
        border-radius: 15px;
        border: 1px solid #010230;
        box-sizing: border-box;
        color: white;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_demi;
        font-size: 16px;
        gap: 0 21px;
        height: 51px;
        justify-content: space-between;
        max-width: 255px;
        padding: 17px 18px;
        width: 100%;

        img {
          height: 25px;
          width: 24px;
        }
      }
    }

    p {
      color: #808080;
      font-family: $primary_font_light;
      font-size: 14px;
      font-style: italic;

      @include breakpoint(desktop-medium) {
        max-width: 60%;
      }
    }
  }
}
