@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.ModalForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .modal-content {
    background-color: white;
    border-radius: 10px;
    padding: 30px 10px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    text-align: center;
    height: 100%;

    @include breakpoint(tablet) {
      padding: 2rem;
      height: min-content;
      max-width: 400px;
    }

    form {
      box-sizing: border-box;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background: none;
      font-size: 1.5rem;
      cursor: pointer;
    }

    h2 {
      margin-bottom: 0.5rem;
      font-size: 1.8rem;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 1.5rem;
    }

    .input-field {
      margin-bottom: 1rem;

      input {
        width: 100%;
        padding: 0.75rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      .error {
        color: red;
        font-size: 0.8rem;
        margin-top: 0.25rem;
        text-align: left;
      }
    }

    .submit-btn {
      width: 100%;
      background-color: #00d5ff;
      color: white;
      padding: 0.75rem;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        background-color: #00a3cc;
      }
    }

    .footer-text {
      margin-top: 1rem;
      font-size: 0.75rem;
      color: gray;
    }
  }
}
