@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.NavigationLink {
  color: $white;
  font-family: $primary_font;
  font-size: pxToRem(15px);
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @include breakpoint(desktop-small) {
    font-family: $primary_font;
    font-size: pxToRem(15px);
    text-transform: unset;
  }
  
  @include breakpoint(desktop-medium) {
    font-family: $primary_font;
    font-size: pxToRem(14px);
    text-transform: unset;
  }

  @include breakpoint(desktop-large) {
    font-family: $primary_font;
    font-size: pxToRem(18px);
    text-transform: unset;
  }

  &.active, &:hover {
    box-shadow: 0 4px 0 -2px $white;
    font-family: $primary_font_demi;
  }
}
