@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.Methodology {
  align-items: flex-start;
  background-color: $dark_blue;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 150px 30px;

  @include breakpoint(desktop-large) {
    padding: 150px 192px 0;
  }

  .sub-title-methodology {
    color: $white;
    font-family: $primary_font;
    font-size: pxToRem(17px);
    padding: 50px 30px;

    span {
      color: $aqua_blue;
    }

    @include breakpoint(desktop-medium) {
      padding: 50px 100px;
    }
  }

  .subtitle-statistics {
    align-items: center;
    border-radius: 29px;
    border: 3px solid #070BB9;
    display: flex;
    justify-content: center;
    min-height: 113px;
    padding: 29px;
    width: 100%;

    p {
      color: $white;
      font-family: $primary_font;
      letter-spacing: 1px;
      font-size: 14px;
      text-align: justify;

      span {
        color: $aqua_blue;
      }
    }

    @include breakpoint(tablet-large) {
      padding: 29px 97px;

      p {
        font-size: 17px;
      }
    }
  }

  .title {
    display: flex;
    height: 49px;

    h4 {
      align-items: center;
      color: $white;
      display: flex;
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
      margin: 0 0 30px 0;
      text-align: center;

      span {
        align-items: center;
        background-color: $aqua_blue;
        border-radius: 50%;
        color: $dark_blue;
        display: flex;
        font-family: $primary_font_demi;
        font-size: pxToRem(30px);
        height: 41px;
        justify-content: center;
        margin-right: 20px;
        width: 41px;

        @include breakpoint(desktop-small) {
          align-items: center;
          flex-direction: column;
          font-size: pxToRem(40px);
          height: 71px;
          justify-content: center;
          margin-right: 40px;
          width: 71px;
        }
      }

      @include breakpoint(desktop-small) {
        flex-direction: row;
        font-size: pxToRem(34px);
        margin-bottom: 116px;
        width: auto;
      }
    }
  }

  .container-methodology {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 140px 0;
    width: 100%;

    .cards {
      align-items: center;
      display: flex;
      flex-direction: column;

      .card-title {
        border-radius: 9px;
        border: 1px solid #070BB9;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 325px;
        justify-content: center;
        padding: 44px;
        width: 100%;

        span {
          color: $aqua_blue;
          font-family: $primary_font_demi;
          font-size: 30px;
          margin-bottom: 42px;
        }

        p {
          color: $white;
          font-family: $primary_font_demi;
          font-size: 19px;
          margin: 0;
        }

        @include breakpoint(desktop-small) {
          max-width: 300px;
          min-width: 300px;
          width: 20%;
        }

        @include breakpoint(desktop-large) {
          max-width: 300px;
          min-width: 300px;
          width: 20%;
        }
      }

      .arrow-blue {
        margin: 50px;
        transform: rotate(90deg);
      }

      .card-info {
        background: #000255;
        border-radius: 9px;
        min-height: 325px;
        padding: 50px;
        width: 100%;

        .top-card {
          display: flex;
          flex-direction: column;
          gap: 0 20px;
          justify-content: space-between;

          @include breakpoint(desktop-medium) {
            gap: 0 60px;
          }

          .info-top-card {
            display: flex;
            flex-direction: column;

            p {
              color: $white;
              font-family: $primary_font;
              font-size: 14px;
              letter-spacing: 1px;
              margin-top: 45px;

              strong {
                color: $aqua_blue;
              }

              @include breakpoint(tablet-large) {
                margin-left: 63px;
              }

              @include breakpoint(desktop-medium) {
                font-size: 17px;
                margin-left: 80px;
              }
            }

            .title-min-card {
              align-items: center;
              display: flex;

              img {
                height: 45px;
                width: 45px;
              }

              p {
                color: $white;
                font-family: $primary_font_demi;
                font-size: 12px;
                margin: 0 0 0 16px;
              }

              @include breakpoint(desktop-medium) {
                img {
                  height: 61px;
                  width: 61px;
                }

                p {
                  font-size: 15px;
                }
              }
            }

            .button-info-card {
              align-items: center;
              display: flex;
              margin-top: 35px;

              p {
                color: $aqua_blue;
                margin: 0;
              }

              .Button {
                font-size: 11px;
                height: 29px;
                justify-content: center;
                margin-left: 20px;
                padding: 0;
                width: 95px;
              }
            }
          }
        }

        @include breakpoint(tablet-large) {
          .top-card {
            flex-direction: row;
          }
        }

        @include breakpoint(desktop-large) {
          max-height: 325px;
          min-height: 325px;
          width: 80%;

          .top-card {
            .info-top-card {
              .title-min-card {
                p {
                  font-size: 21px;
                }
              }
            }
          }
        }
      }

      @include breakpoint(tablet-large) {
        padding: 0 0 0 60px;
      }

      @include breakpoint(desktop-small) {
        flex-direction: row;

        .arrow-blue {
          margin: 0 24px;
          transform: rotate(0);
        }
      }
    }
  }
}