@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.CardsHelp {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  
  .card {
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    height: 323px;
    width: 304px;

    .card-header {
      background-color: #010230;
      border-radius: 8px 8px 0 0;
      height: 171px;
      padding: 23px 22px;

      span {
        color: #01c7f0;
        font-family: $primary_font_demi;
        font-size: 10px;
      }

      div {
        align-items: center;
        display: flex;
        justify-content: space-between;

        p {
          color: white;
          font-family: $primary_font_demi;
          font-size: 17px;
        }

        strong {
          font-size: 71px;
        }
      }
    }

    .body-card {
      display: flex;
      flex-direction: column;
      height: 152px;
      justify-content: space-between;
      padding: 18px 22px;

      p {
        color: #808080;
        font-family: $primary_font_light;
        font-size: 14px;
        max-width: 244px;
      }

      button {
        align-items: center;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 16px;
        border: 1px solid #010230;
        box-sizing: border-box;
        color: #010230;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_demi;
        font-size: 12px;
        gap: 0 8px;
        height: 36px;
        justify-content: space-between;
        padding: 11px 13px;
        width: 174px;

        span {
          font-size: 15px;
        }
      }
    }
  }
}
