@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Navbar {
  align-items: center;
  background-color: $dark_blue;
  box-sizing: border-box;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 24px;
  place-content: center space-between;
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: 100vw;
  z-index: 4;

  img {
    height: 62px;
    width: 168px;
  }

  @include breakpoint(tablet) {
    padding: 0 30px;
  }

  @include breakpoint(desktop-small) {
    padding: 0 70px;
  }

  @include breakpoint(desktop-large) {
    padding: 0 155px 0 190px;
  }

  & > a {
    position: relative;
    text-decoration: none;
    z-index: 3;

    h1 {
      color: $white;
      font-family: $primary_font;
      font-size: 19px;
      margin: 0;
      text-transform: uppercase;
      width: fit-content;
    }
  }

  .hidden {
    display: none;
  }

  & > div {
    align-items: flex-start;
    animation: closeMenu 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    background-color: $dark_blue;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 100vh;
    justify-content: flex-start;
    padding: 150px 0 0 20px;
    position: absolute;
    top: 0;
    width: 100vw;

    @include breakpoint(desktop-small) {
      align-items: center;
      animation: unset;
      background-color: transparent;
      flex-direction: row;
      gap: 30px;
      height: unset;
      justify-content: space-between;
      margin-left: 30px;
      padding: unset;
      position: unset;
      width: 100%;
    }

    @include breakpoint(desktop-extra-large) {
      animation: unset;
      gap: 100px;
    }
  }

  .btn-info {
    align-items: center;
    background: $aqua_blue;
    border-radius: 54px;
    color: black;
    cursor: pointer;
    display: flex;
    font-family: $primary_font_demi;
    font-size: 11px !important;
    font-weight: bold;
    height: 38px;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    transition: filter 0.2s;
    outline: unset;
    border: unset;
    // width: 200px;

    &:hover {
      filter: brightness(0.9);
    }

    @include breakpoint(desktop-small) {
      font-size: 11px !important;
      padding: 0 10px;
    }

    @include breakpoint(desktop-large) {
      font-size: 13px !important;
      padding: 0 20px;
      width: max-content;
    }
  }

  .menu-mobile {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: 40px;
    margin-right: 24px;
    padding: 0;
    z-index: 2;

    @include breakpoint(desktop-small) {
      display: none;
      visibility: hidden;
    }

    div {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      height: 27px;
      justify-content: space-around;
      width: 40px;

      span {
        -webkit-transform-origin: left center;
        background-color: $white;
        display: block;
        height: 3px;
        transform-origin: left center;
        transition: 0.6s;
        width: 30px;
        z-index: 2;
      }
    }
  }

  &.inverted {
    filter: invert(1);
  }

  &.back {
    transition: 0;
    z-index: 1;
  }

  &.menu-active {
    & > div {
      -webkit-animation: openMenu 0.8s ease-in forwards;
      -moz-animation: openMenu 0.8s ease-in forwards;
      -o-animation: openMenu 0.8s ease-in forwards;
      animation: openMenu 0.8s ease-in forwards;

      @include breakpoint(desktop-small) {
        animation: unset;
      }
    }

    .menu-mobile div > span {
      &:nth-child(1) {
        margin-bottom: 2px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        margin-bottom: -2px;
        transform: rotate(-45deg);
      }
    }
  }

  &.menu-closed {
    & > div {
      -webkit-animation: closeMenu 0.8s ease-out forwards;
      -moz-animation: closeMenu 0.8s ease-out forwards;
      -o-animation: closeMenu 0.8s ease-out forwards;
      animation: closeMenu 0.8s ease-out forwards;

      @include breakpoint(desktop-small) {
        animation: unset;
      }
    }
  }

  @keyframes openMenu {
    0% {
      transform: translateX(calc(-100vw - 24px));
    }

    100% {
      transform: translateX(-24px);
    }
  }

  @keyframes closeMenu {
    0% {
      transform: translateX(-24px);
    }

    100% {
      transform: translateX(calc(-100vw - 24px));
    }
  }
}
