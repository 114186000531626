@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.NavbarProcess {
  background-color: #010230;
  display: flex;
  justify-content: center;
  min-height: 85px;
  padding: 0 20px;
  position: fixed;
  width: 100%;

  @include breakpoint(tablet-large) {
    padding: 0 50px;
  }

  @include breakpoint(desktop-medium) {
    padding: 0 100px;
  }

  .container-navbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 1530px;
    width: 100%;

    h1 {
      color: $white;
      font-family: $primary_font_demi;
      font-size: pxToRem(25px);
      margin: 0;

      @include breakpoint(desktop-medium) {
        font-size: pxToRem(45px);
      }

      span {
        color: $aqua_blue;
        display: inline-block;
        transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
      }
    }

    .info-user-navbar {
      align-items: center;
      display: flex;

      .image-info {
        align-items: center;
        background: #00018f 0% 0% no-repeat padding-box;
        border-radius: 50%;
        color: #6b6cff;
        display: flex;
        font-family: $primary_font_demi;
        font-size: 18px;
        height: 36px;
        justify-content: center;
        margin: 0;
        width: 36px;

        @include breakpoint(tablet-large) {
          font-size: 22px;
          height: 46px;
          width: 46px;
        }
      }

      div {
        color: white;
        display: flex;
        flex-direction: column;
        gap: 6px 0;
        margin-left: 25px;
        text-transform: uppercase;

        strong {
          font-family: $primary_font_demi;
          font-size: 13px;
          margin: 0;
        }

        p {
          font-family: $primary_font_medium;
          font-size: 9px;
          margin: 0;
        }

        @include breakpoint(tablet-large) {
          strong {
            font-size: 15px;
          }

          p {
            font-size: 11px;
          }
        }
      }

      button {
        background-color: transparent;
        border: none;
        color: white;
        cursor: pointer;
        font-family: $primary_font_medium;
        font-size: 16px;
        margin-left: 10px;
        outline: unset;
      }
    }
  }
}
