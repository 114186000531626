@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.container {
  overflow: hidden;
}

.download-pdf {
  align-items: center;
  background-color: #010232;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  width: 100%;
  z-index: 20;

  h1 {
    color: $white;
    font-family: $primary_font_demi;
  }

  button {
    background-color: transparent;
    border: 2px solid greenyellow;
    color: white;
    cursor: pointer;
    font-family: $primary_font_demi;
    font-size: 25px;
    height: 50px;
    margin-top: 40px;
    z-index: 10;
  }
}

.Certificate {
  align-items: center;
  background-color: #010232;
  display: flex;
  flex-direction: column;
  height: 1080px !important;
  padding: 60px 30px;
  width: 1872px;

  .container-certificate {
    width: 91%;
    z-index: 1;

    .min-title {
      color: $white;
      font-family: $primary_font;
      font-size: 27px;
      margin: 0 0 20px;
    }

    .title {
      background-color: #000165;
      color: $white;
      font-family: $primary_font_demi;
      font-size: 79px;
      margin: 0;
      padding: 27px 41px;
    }
  }

  .infos-certificate {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    max-width: 91%;
    width: 100%;

    .assinature {
      p {
        color: $white;
        font-family: $primary_font;
        font-size: 18px;
        line-height: 27px;
        margin: 2px 0;
      }

      .date {
        margin-bottom: 30px;
        margin-top: 70px;
      }

      img {
        height: 179px;
        width: 381px;
      }

      .info-assinature {
        margin-top: 15px;

        strong {
          font-family: $primary_font_heavy;
        }
      }
    }

    .table-certificate {
      width: 667px;

      .info-table {
        color: $white;
        font-family: $primary_font;
        font-size: 18px;
        margin-top: 20px;
        text-align: right;
        width: 100%;
      }

      .title-calendar {
        background-color: #03067D;
        color: $white;
        font-family: $primary_font_demi;
        font-size: 15px;
        margin: 0;
        padding: 5px 20px;
        width: 100%;
      }


      ol.collection {
        border-top: 0;
        margin: 0px;
        padding: 0px;
      }

      li {
        list-style: none;
      }

      .item-container {
        display: grid;
        grid-template-columns: 2fr 4fr;

        &:first-child {
          background-color: #03067D;
        }
      }

      .attribute {
        align-items: flex-start;
        border-bottom: 1px solid gray;
        border-left: 1px solid grey;
        border-right: 1px solid gray;
        color: $white;
        display: flex;
        flex-direction: column;
        font-family: $primary_font;
        font-size: 12px;
        gap: 5px;
        justify-content: center;
        overflow: hidden;
        padding: 10px 15px;
        text-overflow: ellipsis;

        p {
          margin: 0;
        }
      }

      .attribute-container {
        color: $white;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));

        &:first-child {
          .attribute {
            font-family: $primary_font_demi;
            font-size: 12px;
          }
        }

        .collection-container>.item-container:first-child {
          font-family: $primary_font_demi;
        }

        .collection-container>.item-container:first-child:hover {
          background-color: transparent;
        }

        .item-container:hover {
          background-color: #03067D;
        }

        .collection-container>.item-container:first-child .attribute {
          align-items: center;
          background-color: #03067D;
          border: none;
          color: $white;
          display: flex;
          flex-direction: row;
          font-family: $primary_font_demi;
          font-size: 15px;
          justify-content: flex-start;
          margin: 0;
          overflow: auto;
          padding: 5px 15px;
          text-overflow: initial;
          white-space: normal;
        }
      }
    }
  }

  .footer {
    align-items: flex-end;
    bottom: 40px;
    display: flex;
    justify-content: space-between;
    margin-top: 180px;
    padding: 0 20px;
    width: 1680px;

    .match {
      height: 98px;
      width: 228px;
    }

    div {
      align-items: flex-end;
      display: flex;

      .logos {
        height: 34px;
        margin-left: 40px;
        width: 94px;

        &.m {
          height: 38px;
          position: relative;
          top: 2px;
          width: 122px;
        }
      }
    }
  }
}
