@import './fonts';

$white: #ffffff;
$dark_blue: #010232;
$aqua_blue: #01C7F0;
$blue: #070BB9;

$primary_font: "Museo Sans Thin";
$primary_font_demi: "Museo Sans Bold";
$primary_font_heavy: "Museo Sans Black";
$primary_font_medium: "Museo Sans Medium";
$primary_font_light: "Museo Sans Light";

$breakpoints: (
 "mobile-medium": 400px,
 "mobile-larger": 576px,
 "tablet": 768px,
 "tablet-large": 992px,
 "desktop-small": 1024px,
 "desktop-medium": 1440px,
 "desktop-large": 1600px,
 "desktop-extra-large": 1800px
);
