@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';


.Marketplace {
  background-color: $dark_blue;
  padding-bottom: 110px;

  .container-marketplace {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(desktop-large) {
      padding: 0 192px;
    }

    a {
      color: $aqua_blue;
    }

    .infos-marketplace {
      padding: 0 30px;

      p {
        color: $white;
        font-family: $primary_font;
        font-size: pxToRem(18px);
        margin: 0 0 35px 0 ;
        max-width: 640px;
        text-align: left;

        &:first-child {
          margin: 0;
          max-width: 858px;
        }

        strong {
          color: #DB1AB8;
          font-family: $primary_font_demi;
          font-size: pxToRem(28px);
        }

        span {
          color: $aqua_blue;
        }

        @include breakpoint(desktop-medium) {
          font-family: $primary_font;
          font-size: pxToRem(18px);
          margin-top: 33px;
          text-align: left;

          &:first-child {
            margin: 0;
            max-width: 960px;
            width: 100%;
          }

          strong {
            color: #DB1AB8;
            font-family: $primary_font_demi;
            font-size: pxToRem(40px);
            max-width: 100% !important;
          }
        }
      }

      @include breakpoint(desktop-medium) {
        padding: 150px 112px 0;
      }

      @include breakpoint(desktop-large) {
        padding: 150px 0 0 0;
      }
    }

    img {
      display: none;
      position: relative;
      width: 20%;

      @include breakpoint(desktop-small) {
        display: flex;
        right: 5%;
        width: 35%;
      }

      @include breakpoint(desktop-large) {
        display: flex;
        max-width: 978px;
        right: -2%;
        top: 100px;
        width: 50%;
      }
    }
  }

  .box-marketplace {
    background: #0B0D54 0% 0% no-repeat padding-box;
    border-radius: 19px;
    height: 230px;
    margin: 0 20px;

    .mobile-infos {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin-top: 50px;
      width: 100%;

      img {
        height: 50px;
        width: 50px;
      }

      div {
        align-items: center;
        display: flex;
        gap: 30px;
        justify-content: flex-start;
        padding-left: 30px;

        p {
          color: $white;
          font-family: $primary_font_demi;
          font-size: pxToRem(23px);
          margin: 0;
          text-align: left;
        }

        @include breakpoint(tablet) {
          p {
            font-size: pxToRem(30px);
          }
        }
      }

      .mobile-info-text {
        color: $white;
        font-family: $primary_font;
        font-size: pxToRem(17px);
        padding: 0 15px 0 30px;
      }
    }

    .desktop-infos {
      display: none;

      div {
        p {
          color: $white;
          font-family: $primary_font;
          font-size: pxToRem(23px);
          margin: 0;
          text-align: left;

          strong {
            color: $white;
            font-family: $primary_font_demi;
            font-size: pxToRem(50px);
          }
        }
      }
    }

    @include breakpoint(desktop-medium) {
      align-items: center;
      border-radius: 29px;
      display: flex;
      height: 224px;
      margin: 130px 108px 0;
      width: 80%;

      .mobile-infos {
        display: none;
      }

      .desktop-infos {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        width: 100%;
      }
    }

    @include breakpoint(desktop-large) {
      margin: 130px 192px 0;
    }
  }

  .container-info {
    padding: 30px 30px;

    div {
      align-items: center;
      border-radius: 29px;
      border: 3px solid #070BB9;
      display: flex;
      height: 133px;
      justify-content: center;
      padding: 0 10px;
      width: 100%;

      img {
        height: 40px;
        margin-right: 20px;
        width: 40px;
      }

      p {
        color: $white;
        font-family: $primary_font_demi;
        font-size: 20px;
      }

      @include breakpoint(desktop-medium) {
        img {
          height: 70px;
          width: 70px;
        }

        p {
          font-size: 30px;
        }
      }
    }

    @include breakpoint(desktop-medium) {
      padding: 150px 192px 0;
    }
  }
}