@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Creators {
  align-items: flex-start;
  background-color: $dark_blue;
  display: flex;
  flex-direction: column;
  padding: 30px;

  @include breakpoint(desktop-medium) {
    padding: 200px 180px 30px 180px;
  }

  .title {
    display: flex;
    height: 10px;

    h4 {
      align-items: center;
      color: $white;
      display: flex;
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
      margin: 0 0 30px 0;
      text-align: center;

      span {
        align-items: center;
        background-color: $aqua_blue;
        border-radius: 50%;
        color: $dark_blue;
        display: flex;
        font-family: $primary_font_demi;
        font-size: pxToRem(30px);
        height: 41px;
        justify-content: center;
        margin-right: 20px;
        width: 41px;

        @include breakpoint(desktop-small) {
          align-items: center;
          flex-direction: column;
          font-size: pxToRem(40px);
          height: 71px;
          justify-content: center;
          margin-right: 40px;
          width: 71px;
        }
      }

      @include breakpoint(desktop-small) {
        flex-direction: row;
        font-size: pxToRem(34px);
        margin-bottom: 116px;
        width: auto;
      }
    }
  }

  .container-creators {
    align-items: center;
    background-color: #000255;
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    gap: 100px 0;
    justify-content: center;
    margin-top: 100px;
    padding: 40px;

    h6 {
      color: $aqua_blue;
      font-family: $primary_font_heavy;
      font-size: pxToRem(140px);
      margin: 0;
    }

    div {
      .ibm-logo {
        background-image: url("../../../assets/images/IBMSkillsBuild.png");
        background-position: -0px;
        background-repeat: no-repeat;
        background-size: contain;
        height: 169px;

        @include breakpoint(mobile-larger) {
          background-size: 350px 35px;
        }
      }

      img {
        height: 169px;
        width: 275px;
        background-image: url("../../../assets/images/ibm-logo.png");
      }

      p {
        color: $white;
        font-family: $primary_font;
        font-size: pxToRem(17px);

        span {
          font-family: $primary_font_demi;
        }

        &:first-child {
          align-items: center;
          display: flex;
          height: 169px;
          margin: 0;
        }

        strong {
          font-family: $primary_font_heavy;
          font-size: pxToRem(35px);
        }
      }
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;
      gap: 0 100px;
      justify-content: space-around;
      align-items: flex-start;
      padding: 100px 150px;
      width: 100%;

      div {
        max-width: 603px;

        p {
          font-size: pxToRem(20px);

          strong {
            font-size: pxToRem(45px);
          }
        }
      }
    }

    @include breakpoint(desktop-medium) {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      gap: 100px;
      justify-content: space-between;
      margin: 100px 0 0 0;
    }
  }

  .formation-creators {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    div {
      margin-top: 60px;

      h1 {
        color: $white;
        font-family: $primary_font_demi;
        font-size: pxToRem(60px);
        margin: 0 0 33px 30px;

        span {
          color: $aqua_blue;
          display: inline-block;
          transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
        }
      }

      p {
        color: $white;
        font-family: $primary_font_light;
        font-size: pxToRem(16px);
        margin: 0 0 0 30px;

        strong {
          font-family: $primary_font_medium;
          font-size: pxToRem(17px);
        }
      }

      .Button {
        display: block;
        margin-top: 20px;
      }
    }

    @include breakpoint(desktop-small) {
      justify-content: center;

      div {
        h1 {
          font-size: pxToRem(122px);
        }

        p {
          font-size: pxToRem(29px);

          strong {
            font-size: pxToRem(32px);
          }
        }

        .Button {
          margin-top: 40px;
        }
      }
    }
  }

  .info {
    margin: 140px 0;
    width: 100%;
    display: flex;
    justify-content: center;

    .info-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .info-all {
        padding: 0;
        font-size: initial;
      }

      .info-all-ibm {
        padding: 0;
        font-size: initial;

        &.partner {
          flex-direction: column;
          display: flex;
          gap: 10px;
          img {
            width: 150px;
          }
        }

        &:last-child {
          margin: 0 0 40px 0;
        }

        .partner-info {
          margin: 40px 0 10px 0;
        }

        .span-partner {
          font-size: 20px;
        }
      }

      p {
        color: $white;
        font-size: pxToRem(20px);
        font-family: $primary_font;
        margin: 0;

        &.min-text {
          font-size: pxToRem(14px);
          margin: 5px 0;
        }

        &.partner-info {
          font-family: $primary_font_light;
          font-size: 14px !important;
          letter-spacing: 1px;
          margin: 50px 0 10px 0;
        }

        &:first-child {
          font-family: $primary_font_medium;
          font-size: pxToRem(26px);
        }

        @include breakpoint(tablet) {
          font-size: pxToRem(29px);
        }
      }

      h1 {
        color: $white;
        font-family: $primary_font_demi;
        font-size: pxToRem(80px);
        margin: 0 0 33px 0;

        span {
          color: $aqua_blue;
          display: inline-block;
          transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
        }

        @include breakpoint(tablet) {
          font-size: pxToRem(122px);
        }
      }

      .Button {
        display: block;
        margin-top: 70px;
        text-align: center;
      }

      .span-partner {
        color: white;
        font-family: $primary_font_heavy;
        font-size: pxToRem(20px);
      }

      @include breakpoint(tablet) {
        padding: 0 192px;

        font-size: pxToRem(122px);
      }
    }
  }
}
