@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.Footer {
  align-items: center;
  background: #0B0D54;
  display: flex;
  flex-direction: column;
  height: 264px;
  justify-content: space-evenly;
  width: 100%;

  @include breakpoint(desktop-small) {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 364px;
    justify-content: space-around;
  }

  p {
    color: $white;
    font-family: $primary_font;
    font-size: pxToRem(20px);
    margin: 0;
    text-align: center;

    &.partner-info {
      font-family: $primary_font_light;
      font-size: 14px !important;
      letter-spacing: 1px;
    }

    a {
      color: $white;
      text-decoration: none;
    }

    @include breakpoint(desktop-small) {
      font-size: pxToRem(26px);
      text-align: left;
    }
  }

  .footer-infos {
    .span-partner {
      color: white;
      font-family: $primary_font_heavy;
      font-size: pxToRem(33px);
    }
  }
}
