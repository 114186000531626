@mixin font-face($font-family, $font-weight, $font-style, $font-display, $local, $woff-suffix) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: $font-display;
    unicode-range: U+000-5FF;
    src: local($local), url($woff-suffix) format('woff');
  }
}


@include font-face("Museo Sans Thin",
  100,
  normal,
  swap,
  "MuseoSans",
  "../fonts/MuseoSansCyrl-100.woff");

@include font-face("Museo Sans Light",
  300,
  normal,
  swap,
  "MuseoSans",
  "../fonts/MuseoSansCyrl-300.woff");

@include font-face("Museo Sans Medium",
  500,
  normal,
  swap,
  "MuseoSans",
  "../fonts/MuseoSansCyrl-500.woff");

@include font-face("Museo Sans Bold",
  700,
  normal,
  swap,
  "MuseoSans",
  "../fonts/MuseoSansCyrl-700.woff");

@include font-face("Museo Sans Black",
  900,
  normal,
  swap,
  "MuseoSans",
  "../fonts/MuseoSansCyrl-900.woff");
