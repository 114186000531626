@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.DevelopmentWeb {
  background-color: #000248;
  border-radius: 22px;
  color: white;
  margin-top: 100px;
  min-height: 600px;
  padding: 20px 20px;
  width: 100%;

  @include breakpoint(desktop-small) {
    padding: 60px 133px;
  }

  h6 {
    color: white;
    font-family: $primary_font_demi;
    font-size: 23px;
    margin: 0;

    strong {
      color: #db1ab8;
    }

    @include breakpoint(desktop-small) {
      font-size: 30px;
    }
  }

  .container-web {
    display: flex;
    flex-direction: column;
    gap: 0 30px;
    justify-content: space-between;
    margin-top: 34px;

    @include breakpoint(desktop-small) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 94px;
    }

    .left-web {
      .texts-left {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @include breakpoint(desktop-small) {
          height: 510px;
        }

        p {
          max-width: 566px;
          font-size: 16px;
          color: white;
          font-family: $primary_font;
          line-height: 35px;
        }
      }

      .container-infos-texts {
        height: 330px;

        .infos-div {
          align-items: center;
          border-radius: 9px;
          border: 1px solid #db1ab8;
          display: flex;
          margin-bottom: 13px;
          max-width: 499px;
          padding: 20px 20px 20px 0;
          position: relative;

          p {
            color: $white;
            font-family: $primary_font;
            font-size: 16px;
            margin: 2px;

            @include breakpoint(desktop-large-extra) {
              font-size: 18px;
            }

            .info-background {
              background-color: #0b0d54;
              border-radius: 9px;
              margin-top: 20px;
              padding: 10px 15px;

              span {
                color: #db1ab8;
                font-family: $primary_font_demi;
              }
            }
          }

          .ball {
            background-color: #db1ab8;
            border-radius: 50%;
            border: 1px solid #db1ab8;
            display: inline-block;
            margin: 0 20px;
            min-height: 8px;
            min-width: 8px;
          }

          .ball-height {
            margin: 0 20px;
            min-height: 8px;
            min-width: 8px;

            .ball {
              left: 0;
              position: absolute;
              top: 28px;
            }
          }
        }
      }

      .end-left {
        height: 200px;
        margin-top: 50px;

        @include breakpoint(desktop-small) {
          margin-top: 100px;
        }

        p {
          color: white;
          font-family: $primary_font;
          font-size: 16px;
          line-height: 35px;
          margin: 0;
          max-width: 566px;
        }
      }
    }

    .right-web {
      .container-image {
        img {
          max-height: 506px;
          max-width: 542px;
          width: 100%;
        }

        @include breakpoint(desktop-small) {
          height: 510px;
        }
      }

      .container-infos-texts {
        height: 330px;

        .infos-div {
          align-items: center;
          border-radius: 9px;
          border: 1px solid #db1ab8;
          display: flex;
          margin-bottom: 13px;
          max-width: 499px;
          padding: 20px 20px 20px 0;
          position: relative;

          p {
            color: $white;
            font-family: $primary_font;
            font-size: 16px;
            margin: 2px;

            @include breakpoint(desktop-large-extra) {
              font-size: 18px;
            }

            .info-background {
              background-color: #0b0d54;
              border-radius: 9px;
              margin-top: 20px;
              padding: 10px 15px;

              span {
                color: #db1ab8;
                font-family: $primary_font_demi;
              }
            }
          }

          .ball {
            background-color: #db1ab8;
            border-radius: 50%;
            border: 1px solid #db1ab8;
            display: inline-block;
            margin: 0 20px;
            min-height: 8px;
            min-width: 8px;
          }

          .ball-height {
            margin: 0 20px;
            min-height: 8px;
            min-width: 8px;

            .ball {
              left: 0;
              position: absolute;
              top: 28px;
            }
          }
        }
      }

      .texts-right {
        margin-top: 50px;
        max-width: 542px;

        @include breakpoint(desktop-small) {
          height: 200px;
          margin-top: 100px;
        }

        span {
          color: #db1ab8;
          font-family: $primary_font_demi;
          font-size: 20px;
        }

        p {
          color: white;
          font-family: $primary_font_demi;
          font-size: 16px;
          line-height: 35px;
          margin: 0;
        }
      }
    }
  }
}
