@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.Statistics {
  align-items: flex-start;
  background-color: $dark_blue;
  display: flex;
  flex-direction: column;
  padding: 30px 30px;

  @include breakpoint(desktop-large) {
    padding: 150px 192px 0;
  }

  .title {
    display: flex;
    height: 80px;

    h4 {
      align-items: center;
      color: $white;
      display: flex;
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
      margin: 0 0 30px 0;
      text-align: center;

      span {
        align-items: center;
        background-color: $aqua_blue;
        border-radius: 50%;
        color: $dark_blue;
        display: flex;
        font-family: $primary_font_demi;
        font-size: pxToRem(30px);
        height: 41px;
        justify-content: center;
        margin-right: 20px;
        width: 41px;

        @include breakpoint(desktop-small) {
          align-items: center;
          flex-direction: column;
          font-size: pxToRem(40px);
          height: 71px;
          justify-content: center;
          margin-right: 40px;
          width: 71px;
        }
      }

      @include breakpoint(desktop-small) {
        flex-direction: row;
        font-size: pxToRem(34px);
        margin-bottom: 116px;
        width: auto;
      }
    }
  }

  .subtitle-statistics {
    align-items: center;
    border-radius: 29px;
    border: 3px solid #070BB9;
    display: flex;
    height: 133px;
    justify-content: center;
    padding: 10px;
    width: 100%;

    p {
      color: $white;
      font-family: $primary_font_demi;
      font-size: 25px;
      text-align: center;

      span {
        color: $aqua_blue;
      }
    }

    @include breakpoint(tablet-large) {
      p {
        font-size: 30px;
      }
    }
  }

  .container-statistics {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 50px 0;
    width: 100%;

    .infos-statistics {
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      justify-content: center;
      max-width: 554px;

      p {
        color: $white;
        font-family: $primary_font;
        font-size: 17px;
      }

      span {
        color: $aqua_blue;
        font-family: $primary_font_demi;
      }
    }

    img {
      margin-top: 60px;
      max-height: 428px;
      max-width: 585px;
      width: 100%;
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;
      padding: 100px 50px;

      .infos-statistics {
        margin-right: 100px;
      }
    }
  }
}
