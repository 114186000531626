@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.Login {
  align-items: center;
  background-color: $dark_blue;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-height: 100vh;
  padding: 60px 30px;

  .background {
    mix-blend-mode: color-dodge;
    opacity: 0.6;
    position: fixed;
    width: 1524px;

    &.one {
      right: 70%;
      top: -35%;
    }

    &.two {
      left: 70%;
      top: 25%;
    }
  }

  .container-login {
    .info {
      display: flex;
      justify-content: center;
      margin: 0 0 140px 0;
      width: 100%;

      @include breakpoint(desktop-medium) {
        margin: 140px 0;
      }

      .info-header {
        p {
          color: $white;
          font-family: $primary_font;
          font-size: pxToRem(12px);
          margin: 0;

          &:first-child {
            font-family: $primary_font_medium;
            font-size: pxToRem(17px);
          }
        }

        img {
          width: 100%;
        }

        h1 {
          color: $white;
          font-family: $primary_font_demi;
          font-size: pxToRem(68px);
          margin: 0 0 33px 0;

          span {
            color: $aqua_blue;
            display: inline-block;
            transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
          }
        }
      }
    }
  }

  .enter-login {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .error {
      color: red;
    }

    div {
      display: flex;
      flex-direction: column;

      label {
        color: $white;
        font-family: $primary_font_demi;
        font-size: 17px;
        margin-bottom: 14px;
      }

      input {
        background: #2f3055;
        border: none;
        color: $white;
        font-size: 25px;
        height: 56px;
        opacity: 1;
        outline: none;
        max-width: 376px;
      }
    }

    button {
      background-color: $aqua_blue;
      color: $white;
      cursor: pointer;
      font-family: $primary_font_demi;
      font-size: 17px;
      height: 56px;
      margin-top: 24px;
      width: 100%;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .creators-info {
    align-items: center;
    bottom: 0;
    display: flex;
    position: fixed;
    right: 100px; 

    div {
      p {
        color: $white;
        font-family: $primary_font;
        font-size: 9px;

        strong {
          font-family: $primary_font_demi;
          font-size: 18px;
        }
      }
    }

    img {
      height: 94px;
      width: 120px;
    }
  }

  @include breakpoint(desktop-medium) {
    justify-content: flex-start;
    padding: 120px 180px 30px 180px;
  }
}