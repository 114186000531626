@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.ProcessStudent {
  background-color: #f2f4f5;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 140px 20px 50px;

  @include breakpoint(tablet-large) {
    padding: 140px 50px 50px;
  }

  @include breakpoint(desktop-medium) {
    padding: 140px 100px 50px;
  }

  .container-infos {
    background: #fafbfb 0% 0% no-repeat padding-box;
    border-radius: 29px;
    box-sizing: border-box;
    max-width: 1530px;
    min-height: 897px;
    padding: 20px 20px;
    width: 100%;

    @include breakpoint(tablet-large) {
      padding: 92px 10px;
    }

    @include breakpoint(desktop-small) {
      padding: 92px 69px;
    }
  }
}
